import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaUserFriends, FaFemale, FaMale } from "react-icons/fa";

const reviews = [
    {
        description: "Reed is a very knowledgeable realtor who steers you straight! He’s responsive and very easy to communicate with. Whenever we had questions, his advice and guidance were spot on. 10/10 recommend",
        author: "Megan Ghrist",
        sale: "Sold a Single Family home in 2022 in Atwater, OH.",
        image: "/assets/images/client/testimonial-1.jpg",
        icon: <FaFemale size="38"/>,
    },
    {
        description: "Very professional and knowledgeable in helping us to sell a property and helping us to purchase another. Kept us on top of all aspects of showings on the property through the closing. Will definitely be using his services in the future!",
        author: "James Romano",
        sale: "Sold a Single Family home in 2022 in Akron, OH.",
        image: "/assets/images/client/testimonial-2.jpg",
        icon: <FaMale size="38"/>,
    },
    {
        description: "An expert in real estate, Reed Havel, is everything a buyer would want in searching and selling a home! Quick to respond in a quick moving market, available when we needed him, always polished and professional, he always maintained character & integrity and made us proud. Thank you, Reed, for learning what we wanted in a perfect-for-us home and helping us find it!!  Our highest recommendation goes to you and we are delighted to know you.",
        author: "Dawn & David Bowman",
        sale: "Bought a Single Family home in 2022 in Canal Fulton, OH.",
        image: "/assets/images/client/testimonial-3.jpg",
        icon: <FaUserFriends size="38"/>,
    },


]


class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            {
                                reviews.map((review, index) => {
                                    return (
                                        <TabPanel key={index}>
                                            <div className="rn-testimonial-content text-center">
                                                <div className="inner">
                                                    <p>{review.description}</p>
                                                </div>
                                                <div className="author-info">
                                                    <h6>
                                                        <span>{review.author}</span>
                                                        <br/>
                                                        {review.sale}
                                                    </h6>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                })
                            }

                            <TabList className="testimonial-thumb-wrapper">
                                {
                                    reviews.map((review, index) => {
                                        return (
                                            <Tab key={index}>
                                                <div className="testimonial-thumbnai">
                                                    <div className="thumb">
                                                        {review.icon}
                                                        {/*<img src={review.image} alt={review.author}/>*/}
                                                    </div>
                                                </div>
                                            </Tab>
                                        )
                                    })
                                }
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
