import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";


class Sell extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Selling Your Home' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">List Your Home with Ease</h2>
                                    <p>Here's practical tips before selling your home.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>You're ready to sell your home if...</h3>
                                                    <p>There is so much uncertainty these days surrounding when is the "right" time to sell your home. The housing market sure isn't becoming more straightforward. Let's check out some important ideas to consider before we put your home up for sale!</p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/sell/money.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>1. You've got equity on your side.</h3>
                                                    <p>For most homeowners, being financially ready to sell your house comes down to one factor: equity. You want to have enough equity to pay off your current mortgage, plus enough left over to make a 20% down payment on your next home. If you can make enough profit to also cover closing costs, moving expenses, or an even larger down payment — that's even better.</p>

                                                    <h3 className="mt--15">2. You’re financially ready to buy.</h3>
                                                    <p>Most people who sell a house buy a new one right away. Take a hard look at your finances. If you've paid off all your non-mortgage debt and have 3-6 months of expenses in your emergency fund, that's a good sign you're financially secure enough to purchase a home again.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12 order-lg-1">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/sell/credit.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                                                    <h3>3. You can pay off all your debt with the money you’ll make from the sale.</h3>
                                                    <p> It’s super radical, but it can be a great financial move!</p>
                                                    <ul className="liststyle mt--15">
                                                        <li>Pay off your current mortgage</li>
                                                        <li>Make the down payment on your new home</li>
                                                        <li>Have money left over to pay off your credit cards, student loans, and car payments</li>
                                                    </ul>

                                                    <h3 className="mt--15">4. You have a new place to live.</h3>
                                                    <p>Before you sell your house, you need to know where you’re going to live next! I can help you with this -- check out the <a href="/buy" target="_blank" rel="noopener noreferrer">Buy</a> page!</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/sell/baby.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>5. You can afford to buy a home that fits your lifestyle better.</h3>
                                                    <p>Do you need another bedroom (or even two) for your growing family? Or, maybe your kids have all moved out and you’re ready to downsize. Whether you’re sizing up or down, make sure your mortgage fits your budget.</p>

                                                    <h3 className="mt--15">6. You can cash flow staging your home and moving.</h3>
                                                    <p>You should spend a little money to stage your home so it’s ready for showings. Focus your home improvement dollars on paint, curb appeal, plus kitchen and bath upgrades. A little bit of fresh paint and elbow grease can go a long way.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-lg-1">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/sell/moving.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                                                    <h3>7. You’re emotionally ready to sell.</h3>
                                                    <p>Selling your home is an emotional issue, too. Before we plant the “For Sale” sign in the front yard, take a minute to ask yourself, <i>Am I really ready to sell my house?</i> Also be ready to keep your house in showing-ready condition throughout the process, and be prepared for the negotiations to come.</p>
                                                    <h3 className="mt--15">8. You understand the market (a little bit).</h3>
                                                    <p>No one can predict how the housing market will perform with 100% accuracy. But having a general idea about inventory levels in your area can go a long when we figuring out what to expect when selling your home.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/buy/reed.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                                                    <h3>7. You have an expert real estate agent you can trust!</h3>
                                                    <p>It’s not easy to find get the best price for your home — that’s why nearly 90% of all home sellers work with agents who eat, sleep and breathe real estate to purchase their home. Having a seller's agent by your side brings two big benefits:</p>
                                                    <ul className="liststyle mt--15">
                                                        <li>Get the best sale price</li>
                                                        <li>Save time</li>
                                                    </ul>
                                                    <p className="mt--15">I would love the opportunity to earn your trust and help you sell your home!</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <CallAction/>
                <Footer />

            </React.Fragment>
        )
    }
}
export default Sell;
