import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiHome , FiFileText , FiDollarSign ,FiChevronUp  } from "react-icons/fi";
import { RiMapPin2Fill } from "react-icons/ri";
import ShowcaseHouses from "../elements/portfolio/ShowcaseHouses";
import ReactGA from "react-ga4";
import { Link } from 'react-router-dom';

import GoogleMapReact from 'google-map-react';

const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--32',
        category: '',
        title: "Northeast Ohio Realtor",
        description: "Hi, I'm Reed. Let's get to work!",
        buttonText: 'Contact Me',
        buttonLink: '/contact'
    },
    // {
    //     textPosition: 'text-left',
    //     bgImage: 'bg_image--31',
    //     category: '',
    //     title: 'Agency.',
    //     description: 'There are many variations of passages but the majority have suffered alteration.',
    //     buttonText: 'Contact Us',
    //     buttonLink: '/contact'
    // },
    
]

const ServiceListOne = [
    {
        icon: <FiHome />,
        title: 'Buy',
        description: "Ready to buy? Let's find you the perfect home!",
        url: "/buy"
    },
    {
        icon: <FiFileText />,
        title: 'Sell',
        description: "Selling your home will be a seamless and easy process.",
        url: "/sell"
    },
    {
        icon: <FiDollarSign />,
        title: 'Invest',
        description: "Generate income through real estate rental properties.",
        url: "/invest"
    },
]


class CorporateBusiness extends Component{
    static defaultProps = {
        center: {
            lat: 41.1537,
            lng: -81.3579
        },
        zoom: 10
    };
    constructor () {
        super()
        this.state = {
            isOpen: false,
            zillowData: []
        }
        this.openModal = this.openModal.bind(this)
        window.asdf = this;
    }
    openModal () {
        this.setState({isOpen: true})
    }

    componentDidMount() {
        console.log("mounted")
        ReactGA.send("home");
        // fetch("/.netlify/functions/zillow")
        fetch("/.netlify/functions/zillow")
            .then((response) => response.json())
            // .then((response) => {
            //     console.log(response.json())
            // })
            .then((zillowData) => {
                console.log(zillowData)
                this.setState({zillowData})
            })


    }

    render(){


        return(
            <Fragment> 
                <Helmet/>

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition} backdrop-filter`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title" style={{textShadow: "black 1px 1px 10px", fontSize: 40}}>{value.title}</h1> : ''}
                                                    {value.description ? <p className="description" style={{
                                                        color: "white",
                                                        textShadow: "black 0px 0px 5px",
                                                        fontWeight: "bold",
                                                        opacity: 1,
                                                        paddingLeft: 0,
                                                    }}>{value.description}</p> : ''}
                                                    {value.buttonText ? (
                                                        <div className="slide-btn">
                                                            <Link className="rn-button-style--2 btn-solid" to={value.buttonLink}>
                                                                {value.buttonText}
                                                            </Link>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link className="text-center" to={val.url}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--90 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="rn-contact-map-area position-relative">
                                    <h3>Past Sales</h3>
                                    <p>These are a few of the sales I've most proud of, as well as a map showing all the sales I've done in Northeast Ohio</p>
                                    <div className="row mb--25" style={{marginTop: -40}}>
                                        <ShowcaseHouses styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                    </div>
                                    <div style={{ height: '550px', width: '100%' }}>
                                        <GoogleMapReact
                                            defaultCenter={this.props.center}
                                            defaultZoom={this.props.zoom}
                                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                        >
                                            {
                                                this.state.zillowData && this.state.zillowData.map((house) => {
                                                    return (
                                                        <a
                                                            href={`https://www.zillow.com/homedetails/5903-Newton-Falls-Rd-Ravenna-OH-44266/${house.zpid}_zpid/`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            lat={house.lat}
                                                            lng={house.long}
                                                            id={house.zpid}
                                                        >
                                                            <RiMapPin2Fill
                                                                size="2em"
                                                                color="#da1d30"
                                                            />
                                                        </a>
                                                    )
                                                })
                                            }
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}


                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;
