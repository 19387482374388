import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle ? `${this.props.pageTitle} | Realtor Reed` : "Realtor Reed | Northeast Ohio Real Estate Agent"}</title>
                    <meta name="description" content="Realtor Reed. Kent, Ohio" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
