import React, { Component } from "react";

const ShowcaseHousesContent = [
    {
        image: 'image-1',
        category: '$695k',
        title: 'Chagrin Falls',
        zillowID: '110626532'
    },
    // {
    //     image: 'image-2',
    //     category: '$425k',
    //     title: 'Canal Fulton',
    //     zillowID: '123538406'
    // },
    {
        image: 'image-3',
        category: '$640k',
        title: 'Hinckley',
        zillowID: '88962605'
    },
    {
        image: 'image-4',
        category: '$910k',
        title: 'Solon',
        directUrl: 'https://www.realtor.com/realestateandhomes-detail/7500-Stockwood-Dr_Solon_OH_44139_M43630-86504'
    },
]

class ShowcaseHouses extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = ShowcaseHousesContent.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => {
                    let link = value.directUrl;
                    if (value.zillowID) {
                        link = `https://www.zillow.com/homedetails/5903-Newton-Falls-Rd-Ravenna-OH-44266/${value.zillowID}_zpid/`;
                    }
                    return (
                        <div
                            className={`${column}`}
                            key={index}
                            onClick={(event)=> {
                                event.preventDefault()
                                window.open(link, "_blank")
                            }}
                            style={{cursor: "pointer"}}
                        >
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail ${value.image}`}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4><a href={link}>{value.title}</a></h4>
                                        <div className="portfolio-button">
                                            <a
                                                className="rn-btn"
                                                href={link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >View Details</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
                )}

            </React.Fragment>
        )
    }
}
export default ShowcaseHouses;
