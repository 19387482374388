import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Testimonial from "../elements/Testimonial";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";


class About extends Component{
    render(){
        let title = 'About Reed Havel',
        description = 'Named a top three agent for Portage County in 2022 by Rate My Agent, Reed has sold 100+ homes, resulting in over $58 million in sales since being licensed in 2019. Whether you’re buying or selling a home, switching realtors, or just curious about the local market, you can expect professional advice and prompt communication when you work with Reed.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                {/*<Breadcrumb title={'About'}   />*/}
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about_cover.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Where I'm from</h3>
                                                    <p>Born and raised in Kent, OH, I also spent some of my childhood in Hudson, where my family now lives.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Why I do this</h3>
                                                    <p>It's my goal to empower everyday people to be in the home of their dreams.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}



                {/* Start Testimonial Area */}
                <div className="row">
                <div className="col-12 text-center">
                        <h4>
                        Please check all of&nbsp;
                        <a
                            href="https://www.zillow.com/profile/reedhavel/#reviews"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            my reviews on Zillow!
                        </a> Here are a few of them:
                        </h4>
                    </div>
                </div>
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About
