import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function ContactForm({props}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm(
            'service_mfdz868',
            'contact_form',
            e.target, 
            'ZQpyF-Pp45-kX5GiZ'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                    type="text"
                    name="client"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input 
                    type="email"
                    name="reply_to"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input 
                    type="tel"
                    name="phone"
                    placeholder="Your Phone Number"
                />
            </div>

            <div className="rn-form-group">
                <select name="buy_sell_invest" id="buy_sell_invest" required>
                    <option value="" defaultValue>I'm looking to...</option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                    <option value="invest">Invest</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div className="rn-form-group">
                <select name="referral" id="referral" required>
                    <option value="" defaultValue>How'd you hear about me?</option>
                    <option value="a referral">Referral</option>
                    <option value="Google">Google</option>
                    <option value="social media">Social Media</option>
                    <option value="word of mouth">Word of Mouth</option>
                    <option value="other">Other</option>
                </select>
            </div>
            
            <div className="rn-form-group">
                <textarea 
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
