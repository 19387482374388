import React, { Component } from "react";


class Test extends Component{

    render(){
        return(
            <React.Fragment>



                <a
                    href="https://glowing-pony-5483cc.netlify.app/?utm_source=instagram&utm_medium=bio&utm_campaign=clicks"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    IG Link
                </a>
                <br/>
                <a
                    href="https://glowing-pony-5483cc.netlify.app/?utm_source=facebook&utm_medium=bio&utm_campaign=clicks"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    FB Link
                </a>

            </React.Fragment>
        )
    }
}
export default Test;
