import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";

class Buy extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Buying a Home" />
        {/* End Pagehelmet  */}

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Buy a Home with Confidence
                  </h2>
                  <p>Here's practical tips before putting in an offer. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--80 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>You're ready to buy a home if...</h3>
                          <p>
                            As your realtor, I want to find a home that works
                            well for you and your family long-term. Let's walk
                            through a few things to consider, so that you can
                            live comfortably in your new home with peace of
                            mind.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="infographic-img"
                            src="/assets/images/buy/saving.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>
                            1. You have healthy savings, with no major debt.
                          </h3>
                          <p>
                            Having no outstanding debt (credit cards, student
                            loans, car loans) and an emergency fund of 3-6
                            months of expenses will set up your home ownership
                            journey for success.
                          </p>

                          <h3 className="mt--15">
                            2. You can afford monthly house payments and home
                            maintenance.
                          </h3>
                          <p>
                            Buying a house is an investment. As your realtor, my
                            goal is to understand your overall financial
                            situation to help guide you to a home that's within
                            your budget. Ideally, your monthly house payment
                            should be no more than 25% of your collective
                            take-home pay. Otherwise, you may find yourself
                            unable to take care of home care and maintenance. To
                            make sure that doesn’t happen, here’s what’s
                            included in that 25% limit:
                          </p>
                          <ul className="liststyle mt--15">
                            <li>Principal</li>
                            <li>Interest</li>
                            <li>Property Taxes</li>
                            <li>Homeowners Insurance</li>
                            <li>Private Mortage Insurance (PMI)</li>
                            <li>Homeowners Association Fees (HOA)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                      <div className="col-lg-6 col-12 order-lg-1">
                        <div className="thumb">
                          <img
                            className="infographic-img"
                            src="/assets/images/buy/contract.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                          <h3>3. You have a good down payment.</h3>
                          <p>
                            A 20% down payment is ideal since it means you’ll
                            avoid PMI payments (and you’ll end up borrowing
                            less). If 20% is not possible, a 5–10% down payment
                            is okay, especially if you’re a first-time home
                            buyer. Just plan to pay that pesky PMI as soon as
                            possible. Remember, the more you put down, the less
                            you have to borrow, and the sooner you’ll be
                            debt-free!
                          </p>

                          <h3 className="mt--15">
                            4. You can pay your own closing costs.
                          </h3>
                          <p>
                            Some home sellers cover closing costs to sweeten the
                            deal—but don’t bank on it, especially in a market
                            like this one. Saving 3–4% for closing costs is a
                            good rule of thumb to cover:
                          </p>
                          <ul className="liststyle mt--15">
                            <li>Loan origination fee</li>
                            <li>Home inspection</li>
                            <li>Appraisal</li>
                            <li>
                              Prepaid property taxes and mortgage insurance
                            </li>
                            <li>Title insurance</li>
                            <li>Recording fees</li>
                            <li>Underwriting fees</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="infographic-img"
                            src="/assets/images/buy/moving.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>5. You can cash flow moving expenses.</h3>
                          <p>
                            Buying a house means you'll be moving! Movers can be
                            $900-$2400 for a local move (or as cheap as a few
                            pizzas for your friends with trucks). Besides the
                            heavy lifting part of your move, here are other
                            expenses to be ready for:
                          </p>
                          <ul className="liststyle mt--15">
                            <li>
                              Boxes, bubble wrap and other moving supplies
                            </li>
                            <li>Deposits for utilities</li>
                            <li>Cleaning supplies</li>
                            <li>
                              Appliances that aren’t included in your home
                              purchase
                            </li>
                            <li>
                              Any pre-move-in upgrades like painting, new
                              furniture and closet organization
                            </li>
                          </ul>

                          <h3 className="mt--15">
                            6. You plan on staying put for a while.
                          </h3>
                          <p>
                            Another thing to think about before you buy a house
                            is the location. Are you planning to stay there for
                            more than a year or two? Remember, the longer you
                            stay put in your home, the more equity you build.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                      <div className="col-lg-6 col-12 order-lg-1">
                        <div className="thumb">
                          <img
                            className="infographic-img"
                            src="/assets/images/buy/reed.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                          <h3>
                            7. You have an expert real estate agent you can
                            trust!
                          </h3>
                          <p>
                            It’s not easy to find a house you love that’s also
                            within your budget — that’s why nearly 90% of all
                            home buyers work with agents who eat, sleep and
                            breathe real estate to purchase their home. Having a
                            buyer’s agent by your side brings two big benefits:
                          </p>
                          <ul className="liststyle mt--15">
                            <li>Save money</li>
                            <li>Save time</li>
                          </ul>
                          <p className="mt--15">
                            I would love the opportunity to earn your trust and
                            help you purchase your dream home!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="details">
                        <p style={{ marginBottom: 30 }}>
                          Explore the possibilities of homeownership with USDA
                          property loans! They are dedicated to helping you
                          discover financing options that suit your needs. Learn
                          more about USDA loans and how they can make your dream
                          home a reality.
                        </p>
                        <div style={{ textAlign: "center" }}>
                          <a
                            href="https://usdaproperties.com/states/OH/start/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="row"
                          >
                            <div className="col-12">
                              <img
                                src="https://usdaproperties.com/imgs/stateFlags/ohio.webp"
                                alt="Ohio state flag"
                              />
                            </div>
                            <div className="col-12 mt--15">
                              USDA Loan Eligible Properties in Ohio
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <CallAction />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Buy;
