import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";


class Invest extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Investing in Real Estate' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Invest in Real Estate</h2>
                                    <p>Here's tips for how to get started.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>Types of Real Estate Investments</h3>
                                                    <p>
                                                        Real Estate is a great long-term investment. Many people believe that getting into real estate can be a big commitment -- and they're right! But with proper preparation, it can create wealth for you and your family for generations. As Mark Twain said, “Buy land. They’re not making it anymore.”
                                                        There are a few great ways to invest in real estate:
                                                    </p>
                                                    <h4>Homeownership</h4>
                                                    <p>
                                                        Homeownership is the route most people take. Specifically, "owning" and paying off the home is what you should focus on. You should pay off your house before investing in any other real estate.
                                                    </p>
                                                    <h4>Rental Properties</h4>
                                                    <p>
                                                        Rental properties are great if you want to bring in thousands of dollars of extra income year after year. And if you ever need to, you can sell them for a nice profit. Just know that if a pipe bursts at 2am on a Saturday night, you'll be getting a phone call!
                                                    </p>
                                                    <h4>House Flipping</h4>
                                                    <p>
                                                        If you're interested in quicker returns than rentals, you can consider house flipping. The key makeing a profit flipping a house is to buy it at a low price. Then, you need to consider if you'll be doing the renovations, or if you'll be hiring a contractor -- and then budget your time and money appropriately.
                                                    </p>
                                                    <p>
                                                        I can speak confidently to the process (and benefits!) of the following principles. Over the past decade, they have served me well across several investment properties.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/invest/money.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>1. Pay in cash</h3>
                                                    <p>For any type of real estate investment, you should always pay in cash! This is contrary to what many "experts" say about carrying "good debt". But I believe that the sooner your investment is 100% yours, the lower your risk is and the quicker you can be making a profit.</p>

                                                    <h3 className="mt--15">2. Diversify.</h3>
                                                    <p>Before diving into real estate investing, make sure you have eliminated all other debts. You'll also want to make sure you have healthy contributions to your retirement accounts (and don't dip into your retirement savings to buy property).</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12 order-lg-1">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/invest/flipping.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30 pb_sm--15">
                                                    <h3>3. Stay local.</h3>
                                                    <p>Be close to your investments! If you live in Ohio, you won't want to keep an eye on your rentals in Florida. Sure, you could hire a management company, but it's always best to be able to see things for yourself.</p>

                                                    <h3 className="mt--15">4. Be prepared for risks.</h3>
                                                    <p>There may be times when your properties do not have renters, appliances break, or a flipped house doesn't sell for as much as you'd hoped. To prepare for these risks, you should have an emergency fund ready to go. Also, paying in cash will help keep risks at bay.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content pb--80 pb_sm--15 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="infographic-img" src="/assets/images/buy/reed.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>5. Start small.</h3>
                                                    <p>If you're not sure if a real estate investment is the right move for you, test it out! Maybe you have a spare room you can AirBnB? Or there's a low priced, local house you can flip? It also helps to talk to others in your area who invest in real estate -- I'm happy to chat.</p>

                                                    <h3 className="mt--15">6. Find the right agent.</h3>
                                                    <p>Buying investment properties comes with more considerations than buying your home, and you need an agent who has done this before. I've been investing in real estate for over 10 years. I have properties that have worked out great, and I've had properties that were problematic. Let's work together to leverage my personal experience and make your real estate investment goals a profitable reality!</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <CallAction/>
                <Footer />

            </React.Fragment>
        )
    }
}
export default Invest;
